img.navbar-brand.logo-img {
    white-space: normal;
    text-align: left !important;
    word-break: break-all;
    margin: 0;
    
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.my-container-class {
    font-size: 16px;
}

/* Media query for small devices */
@media (max-width: 576px) {
    .my-container-class {
        font-size: 11px;
    }
}

@media only screen and (max-width: 300px) {

    .hide-logo {
        display: none !important;
    }
}